import React from 'react'

export default ({ references }) => {
  return (
    <div className='section references'>
      <h2 className='references__header'>
        ETT URVAL AV KUNDER SOM SOUNDCON HAR HAFT NÖJET ATT ARBETA MED
      </h2>
      <span className='references__list'>
        {references.map((reference) => reference.name).join(' | ')}
      </span>
    </div>
  )
}
