import React, { useState, useEffect } from 'react'

import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from 'components/Layout'
import Employee from 'components/Employee'
import References from 'components/References'

import soundwave from '../images/soundwave.svg'

import backgroundVideoMp4 from '../images/film.mp4'
import backgroundVideoWebm from '../images/film.webm'

const Index = ({ data }) => {
  const [showVideo, setShowVideo] = useState()

  const references = data.allReferencesYaml.edges.map((edge) => ({
    name: edge.node.name,
  }))

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(min-width:600px)')

    const adjustToMedia = () => {
      mediaQueryList.matches ? setShowVideo(true) : setShowVideo(false)
    }

    adjustToMedia()

    /**
     * Here we have to use try/catch to meet all systems
     * iOS & Safari doesn't listen to addEventListener and needs addListener, aswell with removeEventListener/removeListener
     **/
    try {
      mediaQueryList.addEventListener('change', adjustToMedia)

      return () => {
        mediaQueryList.removeEventListener('change', adjustToMedia)
      }
    } catch (firstError) {
      try {
        mediaQueryList.addListener(adjustToMedia)

        return () => {
          mediaQueryList.removeListener(adjustToMedia)
        }
      } catch (secondError) {
        console.log(secondError)
      }
    }
  }, [])

  return (
    <Layout pageTitle='Hem'>
      <oma-grid-row className='row' fullWidth={true}>
        {showVideo ? (
          <video loop muted autoPlay className='header__video' aria-hidden>
            <source src={backgroundVideoWebm} type='video/webm' />
            <source src={backgroundVideoMp4} type='video/mp4' />
            Din webbläsare stödjer inte den här typen av video.
          </video>
        ) : (
          <GatsbyImage
            image={data.headerImage.childImageSharp.gatsbyImageData}
            alt='Personalen i Soundcon spelar foosball vid ett foosballbord'
            className='header__image'
          />
        )}

        <section className='section section__hero--centered'>
          <h1 className='section__heading section__heading--primary'>
            <span className='section__heading-text section__heading-text--primary'>
              Soundcon
            </span>
          </h1>

          <p className='section__ingress'>
            Vi har bestämt oss. Med 70 års samlad erfarenhet och över 5000
            uppdrag har vi ett tydligt mål – att alltid anstränga oss lite mer.
            En personlig kundkontakt skapar bra förutsättningar för ett gott
            samarbete. Soundcon är fyra akustikkonsulter som alltid har lite
            roligare…
          </p>

          <p>
            För att ge våra kunder bästa möjliga service är det personliga mötet
            ovärderligt. Vi på Soundcon har stor erfarenhet av
            akustikprojektering och olika typer av bullerutredningar. Soundcon
            utför uppdrag som mätningar, beräkningar och utredningar inom
            byggnadsakustik, rumsakustik, trafikbuller samt yttre och inre
            industribuller. Ljudmiljön på kontoret håller dock inte behaglig
            nivå vid fredagens Calcio balilla.
          </p>
        </section>

        <div class='members'>
          <Employee
            name='Andreas Berg'
            fixedImage={data.andreasSpin.childImageSharp.gatsbyImageData}
            phone='+46734207743'
            email='andreas@soundcon.se'
            id='andreas'
          >
            <p className='member-card__text'>
              Andreas stora intresse är musik och han sitter gärna i studion och
              rattar fram det ultimata trumsoundet. Andreas är kontorets "handy
              man” med stora planer på studio- och husbygge. Om det finns
              möjlighet och tid spelar han även innebandy och deltar i mat –och
              vinprovningar.
            </p>
          </Employee>

          <Employee
            name='Henrik Runström'
            fixedImage={data.henrikSpin.childImageSharp.gatsbyImageData}
            phone='+46734207742'
            email='henrik@soundcon.se'
            id='henrik'
          >
            <p className='member-card__text'>
              Henrik tillbringar så mycket tid som möjligt... och lite tilll...
              i skidbacken. Det blir flera fjällresor om året, helst till
              Fjätervålen. Henrik trivs bäst utomhus, är en hängiven scout och
              har en badsäsong som sträcker sig betydligt längre än för de
              flesta. I Italien samlar han inspiration till sin dolda talang som
              pizzabagare.
            </p>
          </Employee>

          <Employee
            name='Magnus Ingvarsson'
            fixedImage={data.magnusSpin.childImageSharp.gatsbyImageData}
            phone='+46734207740'
            email='magnus@soundcon.se'
            id='magnus'
          >
            <p className='member-card__text'>
              Oavsett årstid, väder och vind tar Magnus cykeln till jobbet. När
              solen gassar kommer racern fram. Han är obotligt morgonpigg och
              bästa starten på dagen är att hinna ett gympass på Friskis &
              Svettis. Magnus gillar "Netflix and chill"... bokstavligen
              alltså... att sjunka ner i soffan och kolla på serier på Netflix.
            </p>
          </Employee>

          <Employee
            name='Torbjörn Appelberg'
            fixedImage={data.torbjornSpin.childImageSharp.gatsbyImageData}
            phone='+46734207741'
            email='torbjorn@soundcon.se'
            id='torbjorn'
          >
            <p className='member-card__text'>
              Torbjörn är kontorets obesegrade mästare i Calcio balilla. Han
              vill helst ha sommar året runt och reser så ofta som möjligt
              söderut med familjen. Torbjörn är en fotbollsnörd och vinnarskalle
              med stort intresse för "il calcio". Fritiden spenderar han mest
              vid bygdens fotbollsplaner. Trots sin tondövhet är kontorets
              hårdrocksfredag veckans höjdpunkt.
            </p>
          </Employee>
        </div>
      </oma-grid-row>
      <oma-grid-row fullWidth>
        <BackgroundImage
          className='section__background-image'
          fluid={data.bygg.childImageSharp.gatsbyImageData}
          style={{ backgroundPosition: 'center' }}
          alt='En övervåning på ett bilbiotek med en trappa'
          id='bygg'
        >
          <p className='section__title'>Bygg</p>
        </BackgroundImage>
      </oma-grid-row>
      <oma-grid-row class='row'>
        <section className='section'>
          <h2 className='section__heading'>
            <span className='section__heading-text'>
              Ljudmiljö och livskvalitet
            </span>
          </h2>

          <div className='section__text'>
            <p>
              En god ljudmiljö i bostäder och andra lokaler har blivit en allt
              mer uppmärksammad miljöfaktor. Forskning visar på ett starkt
              samband mellan bra akustik och människors hälsa. Detta har
              bidragit till ökad efterfrågan på god ljudmiljö.
            </p>

            <p>
              Soundcon upprättar ljudstandarder i era projekt. Vi dimensionerar
              samtliga ingående konstruktioner så att de uppnår krav på bl a
              luftljudsisolering, stegljudsnivåer, rumsakustik, trafikbuller och
              buller från installationer.
            </p>

            <p>
              Vi arbetar i nära relation till byggherrar, entreprenörer,
              arkitekter och konsulter. Soundcon deltar i byggprojektets alla
              faser, från tidig planering till detaljprojektering och
              verifierande mätningar i färdig byggnad. Vår praktiska erfarenhet
              kombinerat med teoretiskt kunnande skapar effektiva lösningar för
              er.
            </p>
          </div>
        </section>
      </oma-grid-row>
      <oma-grid-row fullwidth>
        <BackgroundImage
          className='section__background-image'
          fluid={data.samhalle.childImageSharp.gatsbyImageData}
          style={{ backgroundPosition: 'center' }}
          alt='Tågspår som går mellan två motorvägar, under rusningstrafik'
          id='samhalle'
        >
          <p className='section__title'>Samhälle</p>
        </BackgroundImage>
      </oma-grid-row>
      <oma-grid-row>
        <section className='section'>
          <h2 className='section__heading'>
            <span className='section__heading-text'>
              Allt för en god ljudmiljö
            </span>
          </h2>

          <div className='section__text'>
            <p>
              Samhället utvecklas och fler vill bo i städer eller ha närhet till
              expansiva stadsregioner. Städer växer, tysta ytor blir färre och
              bostäder byggs närmare vägar och tågnät. Nya krav ställs på
              infrastrukturen, och samhället investerar i effektivare
              kommunikationslösningar. För att möta samhällsutvecklingen och
              garantera en god livskvalitet, ställer myndigheterna tydligare
              krav på trafikbullret. Med detta ökar även kraven på effektivare
              planprocesser. Byggnader bör därför utformas så att vistelseytor
              och fasader utsätts för mindre buller. Tidiga utredningar sparar
              mycket pengar och påskyndar utdragna processer för
              detaljplanering.
            </p>

            <p>
              Med hjälp av mätningar och beräkningar kartlägger Soundcon olika
              bullersituationer från bland annat väg-, tåg- och flygtrafik samt
              skjut- och motorsportbanor.
            </p>

            <p>
              Soundcons konsulter tar tidigt del av detaljplaner för att hjälpa
              kunden hitta en optimal lösning enligt krav från kommuner och
              länsstyrelser. Med unik kompetens och avancerade programvaror för
              bullerkartläggning tar vi oss även an stora områden så som städer
              och hela kommuner.
            </p>
          </div>
        </section>
      </oma-grid-row>
      <oma-grid-row fullwidth>
        <BackgroundImage
          className='section__background-image'
          fluid={data.industri.childImageSharp.gatsbyImageData}
          style={{ backgroundPosition: 'center' }}
          alt='Industriområde med tre stycken ventilationstrummor'
          id='industri'
        >
          <p className='section__title'>Industri</p>
        </BackgroundImage>
      </oma-grid-row>
      <oma-grid-row>
        <section className='section'>
          <h2 className='section__heading'>
            <span className='section__heading-text'>Ansvar för närmiljön</span>
          </h2>

          <div className='section__text'>
            <p>
              I takt med att städer växer och nya bostadsområden planeras kommer
              allmänheten allt närmare industriområden och hamnar. Både
              industrier och stadsplanerare måste ta ansvar för hur närmiljön
              påverkas. Det ställs krav på bullernivåer både i den inre och
              yttre miljön. Industrier är skyldiga att presentera utredningar
              för tillsynsmyndighet för att säkerställa att man inte överskrider
              tillåtna nivåer i omgivningen. Industriernas inre bullernivåer
              regleras av personalens arbetsmiljökrav.
            </p>

            <p>
              Soundcon hjälper industrier och kommuner att kartlägga
              bullersituationer. Tillsammans tar vi fram åtgärdsförslag för att
              minska ljudnivån. Uppdragen kan vara allt från kraftvärmeverk i
              stadsmiljöer till naturnära etableringar av bergtäkter.
            </p>
          </div>
        </section>
      </oma-grid-row>
      <oma-grid-row
        fullwidth
        paddingOnChildren
        class='row row--with-theme-color'
      >
        <References references={references} />
      </oma-grid-row>
      <oma-grid-row class='row' fullWidth={true}>
        <div className='soundwave'>
          <img src={soundwave} alt='Ljudvåg' />
        </div>
      </oma-grid-row>
    </Layout>
  )
}

export const query = graphql`
  {
    bygg: file(relativePath: { eq: "ByggLiten.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1000, quality: 90, layout: CONSTRAINED)
      }
    }
    industri: file(relativePath: { eq: "industri.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1000, quality: 90, layout: CONSTRAINED)
      }
    }
    samhalle: file(relativePath: { eq: "samhall.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1000, quality: 90, layout: CONSTRAINED)
      }
    }
    torbjornSpin: file(relativePath: { eq: "spinning-members/torbjorn.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 3600, height: 500, quality: 70, layout: FIXED)
      }
    }
    andreasSpin: file(relativePath: { eq: "spinning-members/andreas.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 3600, height: 500, quality: 70, layout: FIXED)
      }
    }
    henrikSpin: file(relativePath: { eq: "spinning-members/henrik.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 3600, height: 500, quality: 70, layout: FIXED)
      }
    }
    magnusSpin: file(relativePath: { eq: "spinning-members/magnus.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 3600, height: 500, quality: 70, layout: FIXED)
      }
    }
    ncc: file(relativePath: { eq: "references/ncc.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180, quality: 70, layout: FIXED)
      }
    }
    castellum: file(relativePath: { eq: "references/castellum.png" }) {
      childImageSharp {
        gatsbyImageData(width: 115, quality: 70, layout: FIXED)
      }
    }
    hsb: file(relativePath: { eq: "references/hsb.png" }) {
      childImageSharp {
        gatsbyImageData(width: 115, quality: 70, layout: FIXED)
      }
    }
    jonkoping_kommun: file(
      relativePath: { eq: "references/jonkoping-kommun.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 180, quality: 70, layout: FIXED)
      }
    }
    riksbyggen: file(relativePath: { eq: "references/riksbyggen.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180, quality: 70, layout: FIXED)
      }
    }
    siemens: file(relativePath: { eq: "references/siemens.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180, quality: 70, layout: FIXED)
      }
    }
    white: file(relativePath: { eq: "references/white.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180, quality: 70, layout: FIXED)
      }
    }
    headerImage: file(relativePath: { eq: "film-start.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 80, layout: CONSTRAINED)
      }
    }
    allReferencesYaml {
      edges {
        node {
          name
        }
      }
    }
  }
`

export default Index
