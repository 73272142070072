import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import classNames from 'classnames'

export default ({ children, fixedImage, name, phone, email, id }) => {
  const animationTimeWindow = 2400
  const [imageActive, setImageActive] = useState(false)
  const imageClasses = classNames('employee__image', {
    'employee__image--active': imageActive,
  })

  const onImageClick = () => {
    if (imageActive) {
      return
    }
    // this needs to be timed so that it at least lets the css animation finish
    setTimeout(() => setImageActive(false), animationTimeWindow)
    setImageActive(true)
  }

  return (
    <div className='employee' id={id}>
      <div
        className='employee__container'
        onTouchStart={onImageClick}
        onMouseOver={() => setImageActive(true)}
        onMouseLeave={() => setImageActive(false)}
      >
        {fixedImage && (
          <GatsbyImage
            image={fixedImage}
            className={imageClasses}
            alt={`${name}, anställd på Soundcon`}
          />
        )}
      </div>
      <div className='employee__text-area'>
        <h2 className='employee__name'>{name}</h2>
        {children}
        <p className='employee__contact'>
          <span className='employee__contact-category'>Telefon</span>
          <oma-link to={phone} />
        </p>
        <p className='employee__contact'>
          <span className='employee__contact-category'>E-post</span>
          <oma-link to={email} />
        </p>
      </div>
    </div>
  )
}
